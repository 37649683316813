import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import us from '../views/us.vue'
import nonus from '../views/nonus.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/us',
    name: 'us',
    component: us
  },
  {
    path: '/non-us',
    name: 'nonus',
    component: nonus
  }
]

const router = new VueRouter({
  routes
})

export default router
